<template>
  <v-container>
    <v-row dense v-for="(finish, i) in finishes" :key="i">
      <v-col>
        <place-card :finish="finish" :mode="mode" :division="division"></place-card>
      </v-col>
    </v-row>

    <template v-if="dirty">
      <v-fab-transition>
        <v-btn
          color="color3 color3Text--text"
          fab
          fixed bottom right
          style="margin-right: 120px"
          @click.stop="save"
          :loading="saving"
        >
          <v-icon>fas fa-save</v-icon>
        </v-btn>
      </v-fab-transition>
      <v-fab-transition>
        <v-btn
          color="red white--text"
          fab
          fixed bottom right
          style="margin-right: 60px"
          @click.stop="cancel"
          :loading="saving"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-fab-transition>
    </template>
  </v-container>
</template>

<script>
import PlaceCard from './PlaceCard.vue'
import FinishValidator from '@/classes/FinishValidator'

export default {
  props: ['division', 'mode'],
  data () {
    return {
      finishValidator: new FinishValidator(this.division),
      saving: false
    }
  },
  computed: {
    finishes () {
      const finishes = this.mode === 'validate' ? this.finishValidator.finishes : this.finishValidator.finishes.filter((f) => {
        return f.teams.length > 0
      })
      const non = this.mode === 'validate' ? this.finishValidator.nonFinishes : this.finishValidator.nonFinishes.filter((f) => {
        return f.teams.length > 0
      })
      return finishes.concat(non).sort((a, b) => {
        return a.value - b.value
      })
    },
    _mode () {
      return !this.mode || this.mode !== 'validate' ? 'display' : this.mode
    },
    dirty () {
      return this.finishValidator.dirty
    },
    hidePoints () {
      return !!this.finishValidator.finishes.find(f => f.point)
    }
  },
  methods: {
    update () {
      this.finishValidator.update()
      this.division.validFinishes = this.finishValidator.valid
    },
    cancel () {
      this.finishValidator.reset()
      this.update()
    },
    save () {
      this.saving = true
      this.$VBL.team.saveResults(this.division.resultsDto)
        .then(response => {
          this.finishValidator = new FinishValidator(this.division)
          this.$root.$emit('results-saved', this.division.name)
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          this.saving = false
        })
    }
  },
  components: {
    'place-card': PlaceCard
  },
  watch: {
    division: function (val) {
      if (!val) return
      this.finishValidator = new FinishValidator(this.division)
    },
    'division.playoffFormat': function (val) {
      if (!val) return
      this.finishValidator.update()
    }
  }
}
</script>

<style>
.color5 {
  border-top: 1px solid rgba(0,0,0,0.12) !important;
}
</style>
