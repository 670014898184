<template>
  <v-card v-if="_mode === 'display'" class="elevation-1">
    <v-toolbar color="color1 color1Text--text" dense>
      <v-toolbar-title>
        {{title}}<sup v-if="suffix">{{suffix}}</sup>
        <span v-if="suffix">Place</span>
        {{subtitle}}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <div v-if="(!mode || mode !== 'validate') && !hidePoints && sb">{{finish.points}} pts</div>
    </v-toolbar>
    <v-container>
      <template v-for="(team, i) in finish.teams">
        <v-row
          v-if="avatars && team.players && team.players.length > 0"
          :key="'avatars'+i"
        >
          <v-col class="text-center" cols="6" v-for="(player,i) in team.players" :key="'player'+i">
            <v-avatar class="color2" size="36px">
              <v-icon color="color2Text">fas fa-user</v-icon>
            </v-avatar>
            <div>
              <player-link :player="player"></player-link>
            </div>
          </v-col>
        </v-row>
        <v-row dense :key="'name'+i" v-else>
          <v-col cols="12" v-if="useTeamName">
            <team-link :tournamentId="tournament.id" :team="team"></team-link>
          </v-col>
          <v-col cols="12" v-else>
            <template v-for="(player, i) in team.players">
              <span :key="'team' + team.id + 'player'+ i + 's'" v-if="i > 0"> / </span>
              <player-link :player="player" :key="'team' + team.id + 'player'+ i"></player-link>
            </template>
          </v-col>
        </v-row>
      </template>
    </v-container>
  </v-card>
  <v-card v-else>
    <v-toolbar color="color1 color1Text--text" dense>
      <v-toolbar-title>
        {{title}}<sup v-if="suffix">{{suffix}} </sup>
        <span v-if="suffix">Place</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <div class="mr-1">
        <v-avatar size="26px" color="white">
          <v-icon v-if="finish.valid" class="green--text">far fa-check-circle</v-icon>
          <v-icon v-else-if="finish.valid === false" class="red--text">fas fa-exclamation-circle</v-icon>
          <v-icon v-else>far fa-circle</v-icon>
        </v-avatar>
      </div>
    </v-toolbar>
    <v-container>
      <template v-for="(team, i) in finish.teams">
        <v-row
          v-if="avatars && team.players && team.players.length > 0"
          :key="'avatars'+i"
        >
          <v-col class="text-center" cols="6" v-for="(player,i) in team.players" :key="'player'+i">
            <v-avatar class="color2" size="36px">
              <v-icon color="color2Text">fas fa-user</v-icon>
            </v-avatar>
            <div>{{player.name}}</div>
          </v-col>
        </v-row>
        <v-row dense :key="'name'+i" v-else align="center">
          <v-col cols="12">
            {{team.name}}
          </v-col>
          <v-col class="text-right" cols="2" v-if="false">
            <v-btn
              text
              icon
              small
            >
              <v-icon>fas fa-trash</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </v-container>
  </v-card>
</template>

<script>
import PlayerLink from '@/components/Player/PlayerLink.vue'
import TeamLink from '@/components/Team/TeamLink.vue'
import { mapGetters } from 'vuex'

export default {
  props: ['finish', 'footnote', 'mode', 'hidePoints', 'division'],
  data () {
    return {

    }
  },
  computed: {
    ...mapGetters(['tournament']),
    sb () {
      return this.tournament.sanctioningBody
    },
    _mode () {
      return !this.mode || this.mode !== 'validate' ? 'display' : this.mode
    },
    title () {
      if (this.finish.value === 1) return 'Champions'
      if (this.finish.value > 99999) return 'Participants'
      return this.finish.value
    },
    subtitle () {
      return (this.useTeamName && this.avatars && this.finish.teams && this.finish.teams.length === 1) ? ` - ${this.finish.teams[0].name}` : null
    },
    useTeamName () {
      return this.division.numOfPlayers > 2
    },
    suffix () {
      if (this.finish.value !== this.title) return null
      const j = this.finish.value % 10
      const k = this.finish.value % 100
      if (j === 1 && k !== 11) return 'st'
      if (j === 2 && k !== 12) return 'nd'
      if (j === 3 && k !== 13) return 'rd'
      return 'th'
    },
    avatars () {
      return this.finish.value < 3 && true
    }
  },
  components: {
    PlayerLink,
    TeamLink
  }
}
</script>

<style scoped>
</style>
