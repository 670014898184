<template>
  <v-card class="grey lighten-3">
    <v-card-text>
      <v-expansion-panels popout v-model="panel" multiple>
        <template v-for="(division, i) in divisions.filter(f => !f.isCoaches && !f.isClinic && !f.noResults)">
          <v-expansion-panel
            v-for="(group, i2) in division.resultsGrouped2"
            :key="`${i}-${i2}`"
            ripple
            :style="i > 0 || i2 > 0 ? 'border-top: 1px solid white !important' : null"
            class="grey lighten-4"
          >
            <v-expansion-panel-header color="color1 color1Text--text" class="title py-2">
              {{division.resultsGrouped2.length === 1 ? division.name : group.name}}
              <template v-slot:actions>
                <v-icon color="color1Text">$expand</v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
                <loading-bar :value="!division.hydrated && false"></loading-bar>
                <new-results-list v-if="!tournament.props.oldResults" :division="division" :places="group.places"></new-results-list>
                <results-list :division="division" v-else></results-list>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </template>
      </v-expansion-panels>
    </v-card-text>
  </v-card>
</template>

<script>
import ResultsList from './DivisionResults.vue'
import NewResultsList from './DivisionResultsPublic.vue'
import { mapGetters } from 'vuex'

export default {
  props: ['divisions'],
  data () {
    return {
      panel: [0]
    }
  },
  computed: {
    ...mapGetters(['tournament'])
  },
  components: {
    ResultsList,
    NewResultsList
  }
}
</script>

<style>
.color5 {
  border-top: 1px solid rgba(0,0,0,0.12) !important;
}
</style>
