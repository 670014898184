<template>
  <v-container fluid class="pa-0">
    <!-- ADMIN -->
    <admin-view v-if="manage && view.admin" :tournament="tournament" ref="admin"></admin-view>
    <!-- PUBLIC -->
    <template v-else>
      <!-- DELETED -->
        <got-blocked v-if="tournament && tournament.isDeleted && !tournament.redirect">
          <h1 >Well technically, you aren't blocked but, there is no volleyball term for "File Not Found"</h1>
        </got-blocked>
      <!-- CANCELED -->
      <template v-else>
        <!-- REDIRECT -->
          <v-overlay :value="true" color="rgba(0,0,0,0.7)" absolute z-index="4" v-if="tournament && tournament.redirect">
            <div class="d-flex justify-center align-center text-center">
              <span class="text-h3 pa-12 overlayText overlayText--fixed">
                This event has moved. <router-link :to="redirectTo">Click here</router-link> to be redirected
              </span>
            </div>
          </v-overlay>
          <v-overlay :value="true" color="rgba(0,0,0,0.7)" absolute z-index="4" v-else-if="tournament && tournament.isCanceled">
            <div class="d-flex justify-center align-center text-center">
              <span class="text-h3 pa-12 overlayText overlayText--fixed">This event has been canceled</span>
            </div>
          </v-overlay>
        <!-- BROCHURE -->
          <tournament-brochure :tournament="tournament" ref="brochure" :key="tournament.id"></tournament-brochure>
        </template>
    </template>
    <!-- MANAGE FAB  -->
    <v-tooltip top v-if="view.admin && !hideButton">
      <span class="text-center">{{`Tournament ${manage ? 'Brochure' : 'Admin'}`}}</span>
      <template v-slot:activator="{ on }">
        <v-btn
          v-on="on"
          color="color3 color3Text--text"
          fab
          bottom
          right
          fixed
          @click.stop="toggleManage"
        >
          <v-icon>{{`fas fa-${manage ? 'file-image' :'user-shield'}`}}</v-icon>
        </v-btn>
      </template>
    </v-tooltip>
    <!-- COURT MAP -->
    <v-dialog
      v-model="mapDialog"
      fullscreen
      scrollable
      v-if="courtMap && !manage"
      transition="dialog-bottom-transition"
    >
      <template v-slot:activator="{ on }">
        <v-btn
          color="color3 color3Text--text"
          :style="view.public ? null : 'margin-right: 60px'"
          fab bottom right fixed
          v-on="on"
        >
          <v-icon>fas fa-map-marked-alt</v-icon>
        </v-btn>
      </template>
      <v-card fill-height>
        <div>
          <v-toolbar color="color1 color1Text--text">
            <v-toolbar-title>Court Map</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn color="color1Text" text icon @click.stop="mapDialog = false">
              <v-icon>fas fa-times</v-icon>
            </v-btn>
          </v-toolbar>
        </div>
        <v-card-text class="pa-0" fill-height>
          <v-img
            contain
            max-width="100%"
            width="100%"
            :src="courtMap"
          ></v-img>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- HOW TO -->
    <v-dialog
      v-model="howTo"
      scrollable
      max-width="500px"
      transition="dialog-transition"
    >
      <v-card>
        <v-card-title class="headline">
          <div>Welcome to</div>
          <fit-text>{{tournament.name}}</fit-text>
        </v-card-title>
        <v-card-text>
          To navigate the tournament use the <span id="menu-text">menu</span> in the top left corner.
          You can find all the teams, divisions, pools and brackets.
          <v-checkbox label="Don't show me this again" v-model="noShow" hide-details color="success"></v-checkbox>
        </v-card-text>
        <v-card-actions class="pt-0">
          <v-spacer></v-spacer>
          <v-btn color="color3" text @click.stop="howTo = false">Got it</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- VBL ADMIN -->
    <v-row v-if="user && user.vbl && this.tournament">
      <v-col class="text-right" cols="12" >
        <round-order-drawer
          :tournament="tournament"
          ref="reorder"
        ></round-order-drawer>
        <v-btn
          color="color3"
          text
          class="xsmall"
          :loading="loading"
          @click.stop="reorder"
        >Reorder Rounds</v-btn>
        <v-btn
          color="color3"
          text
          class="xsmall"
          :loading="loading"
          @click.stop="pointRerun"
        >re-run points</v-btn>

        <match-print v-if="false"></match-print>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// import AdminView from './Admin.vue'
import TournamentBrochure from '@/components/Tournament/Brochure/Brochure.vue'
import { mapGetters } from 'vuex'
import * as mutations from '@/store/MutationTypes'
import loadTournament from '@/router/methods/loadTournament'
const AdminView = () => import('@/Pages/Tournament/Admin.vue')
const RoundOrderDrawer = () => import('@/components/Tournament/RoundOrderDrawer')
const MatchPrint = () => import('@/components/Printing/MatchPrint.vue')

export default {
  data () {
    return {
      howTo: false,
      noShow: false,
      connector: null,
      mapDialog: false,
      loading: false
    }
  },
  computed: {
    ...mapGetters(['admin', 'publicView', 'tournament', 'view', 'noHowTo', 'theme', 'user']),
    unfinished () {
      return this.$refs && this.$refs.brochure && this.$refs.brochure.registration.isLead
    },
    hideButton () {
      return this.manage && this.$refs && this.$refs.admin && this.$refs.admin.currentTab === 'emailer'
    },
    courtMap () {
      const c = this.tournament && this.tournament.props.find(f => f.startsWith('courtMap='))

      return c ? c.replace('courtMap=', '') : false
    },
    manage () {
      return this.$route.name.startsWith(`${this.routeBase}-admin`)
    },
    routeBase () {
      return this.tournament.isTrainingSession ? 'session' : this.tournament.isLeague ? 'league' : 'tournament'
    },
    redirectTo () {
      const result = {
        name: this.$route.name,
        params: this.$route.params,
        query: this.$route.query
      }
      if (this.tournament && this.tournament.redirect) {
        result.params.tournamentId = this.tournament.redirect
      }
      return result
    }
  },
  methods: {
    toggleManage () {
      const to = {
        name: this.$route.name === this.routeBase + '-brochure' ? this.routeBase + '-admin' : this.routeBase + '-brochure',
        params: this.$route.params
      }
      this.$router.push(to)
    },
    pointRerun () {
      this.loading = true
      this.$VBL.points.rerun(this.tournament.id)
        .finally(() => { this.loading = false })
    },
    reorder () {
      this.$refs && this.$refs.reorder.open()
    }
  },
  components: {
    TournamentBrochure,
    AdminView,
    RoundOrderDrawer,
    MatchPrint
  },
  beforeRouteLeave (to, from, next) {
    if (!this.unfinished) {
      next()
    } else {
      const answer = window.confirm('You have an incomplete registration!\nIf you leave now you will have to start over')
      if (answer) {
        next()
      } else {
        this.$refs.brochure.activeTab = 'register'
        next(false)
      }
    }
  },
  watch: {
    howTo: function (val) {
      if (!val) {
        this.noShow && this.$store.commit(mutations.SET_NO_HOWTO)
      }
    }
  },
  created () {
    if (this.manage && !this.admin) {
      this.$router.replace({
        name: this.routeBase + '-brochure',
        params: this.$route.params
      })
    }
    if (!this.noHowTo) {
      if (this.tournament && !this.tournament.isCanceled && this.tournament.startDate && this.tournament.startDate.add(-1, 'd').isBefore()) this.howTo = true
    }
  },
  beforeRouteUpdate: loadTournament
}
</script>

<style>
.overlayText {
  border: 5px solid white;
  border-radius: 13px;
  max-width: 95vw;
}
.overlayText--fixed {
  position: fixed;
  top: 25%;
}

</style>
