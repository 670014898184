<template>
  <v-container fluid :class="{'pa-0': $vuetify.breakpoint.smAndDown}" v-if="!inIFrame">
    <v-row dense v-if="admin && !publicView && !tournament.isPublic">
      <v-col cols="12" :class="{'pa-0': $vuetify.breakpoint.smAndDown}">
        <v-alert type="warning" :value="!tournament.isPublic" icon="far fa-eye-slash" class="ma-0">
          Only you can see this. This tournament is not yet published.
          <v-btn
            color="white"
            light
            :loading="publishing"
            @click.stop="onPublish"
          >Publish</v-btn>
        </v-alert>
      </v-col>
    </v-row>
    <v-row dense v-if="(admin && !publicView && !complete) || tournament.props.includes('showCount')">
      <v-col cols="12" :class="{'pa-0': $vuetify.breakpoint.smAndDown}">
        <v-alert type="info" v-model="regAlert" dismissible transition="scale-transition" class="ma-0">
          <span v-if="admin && !publicView">Currently, you have {{tournament.teamCount}} teams registered and {{tournament.waitlistCount}} on the waitlist.</span>
          <span v-else>{{tournament.teamCount}} teams already registered, get your team in now!</span>
        </v-alert>
      </v-col>
    </v-row>
    <BidStream></BidStream>
    <v-row dense>
      <v-col cols="12" :class="{'pa-0': $vuetify.breakpoint.smAndDown}">
        <v-card>
          <!-- Pic and Title -->
          <v-img
            :src="tournament.coverPhotoUrl"
            :cover="!tournament.containCover"
            :contain="tournament.containCover"
            :position="pos"
            v-if="!isFlyer"
            :aspect-ratio="21/9"
          >
            <v-toolbar
              v-if="!noTitle"
              :floating="true"
              dense
              :class="{'ma-1': $vuetify.breakpoint.xs, 'ma-2': $vuetify.breakpoint.sm, 'ma-3': $vuetify.breakpoint.mdAndUp}"
              :style="`background-color: rgba(0, 0, 0, .7);${fontSize}`"
            >
              <v-toolbar-title class="white--text mr-4" :style="`white-space: inherit; ${fontSize}`">
                {{tournament.name}}
              </v-toolbar-title>
            </v-toolbar>
            <div
              class="pa-0 d-flex justify-space-between"
              style="position: absolute; width: 100%;z-index: 4; top: 0"
            >
              <v-spacer></v-spacer>
              <v-btn
                fab small
                @click.stop="bookMe"
                :loading="booking"
                color="rgba(0, 0, 0, .7)"
                :class="{'ma-1': $vuetify.breakpoint.xs, 'ma-2': $vuetify.breakpoint.sm, 'ma-3': $vuetify.breakpoint.mdAndUp}"
              >
                <v-icon color="white">
                  fa{{bookmarked ? 's' : 'r'}} fa-heart
                </v-icon>
              </v-btn>
            </div>
          </v-img>
          <v-toolbar color="color1 color1Text--text" dense v-if="isFlyer" flat>
            <v-toolbar-title :style="`white-space: inherit; ${fontSize}`">
              {{tournament.name}}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
              fab small
              @click.stop="bookMe"
              :loading="booking"
              color="rgba(0, 0, 0, .7)"
              :class="{'ma-1': $vuetify.breakpoint.xs, 'ma-2': $vuetify.breakpoint.sm, 'ma-3': $vuetify.breakpoint.mdAndUp}"
            >
              <v-icon color="white">
                fa{{bookmarked ? 's' : 'r'}} fa-heart
              </v-icon>
            </v-btn>
          </v-toolbar>
          <!-- Date Bar -->
          <v-toolbar
            v-if="!$vuetify.breakpoint.xs"
            text
            dense
            color="color1 color1Text--text"
            tile flat
          >
            <v-toolbar-title :style="fontSize">
              {{dateDisplay}}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-title :style="fontSize">
              {{tournament.locationNames | formatArraySorted}}
            </v-toolbar-title>
          </v-toolbar>
          <v-card-title v-else color="color1 color1Text--text">
            {{dateDisplay}}
            <br>
            {{tournament.locationNames | formatArraySorted}}
          </v-card-title>
          <v-alert
            type="info"
            text border="top"
            tile prominent
            :value="true"
            class="mb-0"
            v-for="s in tournament.tournamentSeries"
            :key="s.titleTag"
          >
            This tournament is part of the <router-link style="color: inherit !important" :to="`/series/${s.titleTag}`">{{s.title}}</router-link>
          </v-alert>
          <!-- tabs -->
          <v-tabs
            ref="tabs"
            fixed
            :icons-and-text="!$vuetify.breakpoint.xs"
            centered tile
            v-model="activeTab"
            background-color="color2"
            slider-color="color3"
            class="br-none"
          >
          <v-tab href="#leaderboard" ripple v-if="tournament.leaderboard" append>
              <span class="hidden-xs-only color2Text--text">{{tournament.leaderboardTitle}}</span>
              <v-icon color="color2Text">fas fa-trophy</v-icon>
            </v-tab>
            <v-tab href="#teams" ripple v-if="tournament.props.includes('teamSum') && tournament.isCompetitive && (!upcoming || tournament.publicRounds.length > 0) && tournament.checkUserTeams" append>
              <span class="hidden-xs-only color2Text--text">Teams</span>
              <v-icon color="color2Text">fas fa-users-class</v-icon>
            </v-tab>
            <v-tab href="#information" ripple v-if="!complete || showBrochure" append>
              <span class="hidden-xs-only color2Text--text">Information</span>
              <v-icon color="color2Text">fas fa-info-circle</v-icon>
            </v-tab>
            <v-tab href="#location" ripple v-if="!complete && tournament.locationCount" append>
              <span class="hidden-xs-only color2Text--text">Location</span>
              <v-icon color="color2Text">fas fa-map-marker-alt</v-icon>
            </v-tab>
            <v-tab href="#register" ripple v-if="!!tournament.locationCount && (upcoming || tournament.regOpen || (user && user.id === -13))" append>
              <span class="hidden-xs-only color2Text--text">Register</span>
              <v-icon color="color2Text">fas fa-clipboard-check</v-icon>
            </v-tab>
            <v-tab href="#results" ripple v-if="tournament.isCompetitive && !upcoming && tournament.hasFinishes && tournament.hasResults" append>
              <span class="hidden-xs-only color2Text--text">Results</span>
              <v-icon color="color2Text">fas fa-trophy</v-icon>
            </v-tab>
            <v-tab href="#schedule" ripple v-if="tournament.isTrainingSession" append>
              <span class="hidden-xs-only color2Text--text">Schedule</span>
              <v-icon color="color2Text">fas fa-calendar</v-icon>
            </v-tab>

            <v-tab-item value="leaderboard" v-if="tournament.leaderboard">
              <leaderboard :active="activeTab == 'leaderboard'"></leaderboard>
            </v-tab-item>

            <!-- SUMMARY -->
            <v-tab-item value="teams" v-if="tournament.props.includes('teamSum') && tournament.isCompetitive && (!upcoming || tournament.publicRounds.length > 0) && tournament.checkUserTeams">
              <team-summary
                v-if="tournament.isNcaa"
                :tournament="tournament"
              ></team-summary>
              <club-team-summary
                v-else
                :tournament="tournament"
              ></club-team-summary>
            </v-tab-item>

            <!-- INFO -->
            <v-tab-item value="information" v-if="!complete || showBrochure">
              <v-container fluid>
                <v-row dense v-if="tournament.infoAlerts && tournament.infoAlerts.length > 0">
                  <v-col cols="12">
                      <v-alert
                        v-for="alert in tournament.infoAlerts"
                        :key="alert.id"
                        :type="alert.type"
                        :value="true"
                        :dismissible="alert.dismissable"
                        prominent
                      >
                        {{alert.text}}
                      </v-alert>
                  </v-col>
                </v-row>
                <v-row dense>
                  <!-- Info -->
                  <v-col cols="12" class="pa-0" :order="active && tournament.isCompetitive ? 'last' : 'first'">
                    <v-card flat>
                      <v-toolbar dense color="color1 color1Text--text" tile v-if="active && tournament.isCompetitive">
                        <v-toolbar-title>{{tournament.eventType}} Information</v-toolbar-title>
                      </v-toolbar>
                      <v-img
                        v-if="isFlyer"
                        :src="tournament.coverPhotoUrl"
                        width="100%"
                      ></v-img>
                      <v-card-text class="brochure ql-editor" v-if="tournament.description" v-html="tournament.description"></v-card-text>
                    </v-card>
                  </v-col>
                  <!-- divisions -->
                  <v-col cols="12" class="pa-0" :order="active && tournament.isCompetitive ? 'first' : 'last'" v-if="!tournament.isTrainingSession">
                    <v-card flat>
                      <v-toolbar dense color="color1 color1Text--text" tile>
                        <v-toolbar-title>{{tournament.isCompetitive ? 'Divisions' : 'Registration'}}</v-toolbar-title>
                      </v-toolbar>
                      <division-list :divisions="tournament.publicDivisions" @register-click="register" mode="list"></division-list>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item>
            <!-- LOCATION -->
            <v-tab-item value="location" v-if="!complete && !!tournament.locationCount">
              <v-card flat>
                <v-card-title v-if="tournament.isOneLocation">
                  <iframe width="100%" height="450" frameborder="0" style="border:0" :src="tournament.divisions[0].location.googleUrl" allowfullscreen></iframe>
                </v-card-title>
                <v-tabs
                  v-else
                  v-model="activeLocation"
                  color="color2Text color2--text"
                  slider-color="color2"
                  fixed
                  centered
                >
                  <v-tab v-for="location in tournament.locations" :key="location.id">
                    {{location.name}}
                  </v-tab>

                  <v-tab-item v-for="location in tournament.locations" :key="location.id">
                    <v-card>
                      <v-card-text>
                        <iframe width="100%" height="450" frameborder="0" style="border:0" :src="location.googleUrl" allowfullscreen></iframe>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                </v-tabs>
              </v-card>
            </v-tab-item>
            <!-- REGISTER -->
            <v-tab-item value="register" v-if="!!tournament.locationCount && (upcoming || tournament.regOpen || (user && user.id === -13))">
              <v-card flat>
                <tournament-registration
                  v-if="registering"
                  :tournament="tournament"
                  :registration="registration"
                  :inView="activeTab === 'register'"
                  @registered="onRegistered"
                  @added-to-cart="onAddToCart"
                ></tournament-registration>
              </v-card>
            </v-tab-item>
            <!-- RESULTS -->
            <v-tab-item value="results" v-if="tournament.isCompetitive && !upcoming && tournament.hasFinishes && tournament.hasResults">
              <results-display
                :divisions="tournament.divisionsWithTeams"
              ></results-display>
              <v-container class="fill-height" v-if="!complete && false">
                <v-row dense align="center">
                  <v-col cols="8" offset="2">
                    <v-row class="text-center"   >
                      <v-col cols="12">
                        <h3>Awaiting results</h3>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item>
            <!-- SCHEDULE -->
            <v-tab-item value="schedule" v-if="tournament.isTrainingSession">
              <session-calendar></session-calendar>
            </v-tab-item>
          </v-tabs>
        </v-card>
      </v-col>
      <v-col cols="12" class="text-center">
        <v-btn
          color="color3 color3Text--text"
          @click.stop="gotoEvents"
        >
          See All {{ tournament.organization.name }} events
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
  <v-container v-else>
    <v-row dense>
      <v-col cols="12">
        <v-card>
          <tournament-registration
            v-if="registering"
            :tournament="tournament"
            :registration="registration"
            :inView="activeTab === 'register'"
            @registered="onRegistered"
            @added-to-cart="onAddToCart"
          ></tournament-registration>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from 'moment'
import DivisionList from '@/components/Tournament/Brochure/DivisionListNew.vue'
import RegistrationUI from '@/components/Tournament/Registration/Registration.vue'
import * as StatusEnum from '@/classes/TournamentStatus'
import ResultsDisplay from '@/components/Tournament/Results/Display/ResultsDisplay.vue'
import { mapGetters } from 'vuex'
const Leaderboard = () => import('./Leaderboard')
const TeamSummary = () => import('./TeamSummary')
const ClubTeamSummary = () => import('./ClubTeamSummary')
const SessionCalendar = () => import('@/New/SessionCalendar')

export default {
  props: ['tab'],
  data () {
    return {
      registering: true,
      registration: null,
      activeTab: null,
      publishing: false,
      loadingTeams: false,
      search: null,
      regAlert: true,
      activeLocation: 0,
      booking: false,
      summary: null
    }
  },
  computed: {
    ...mapGetters(['admin', 'publicView', 'tournament', 'inIFrame', 'user', 'myEvent', 'theme']),
    isFlyer () {
      return this.tournament.props && this.tournament.props.includes('isFlyer')
    },
    divisionsWithTeams () {
      return this.tournament.divisions.filter(division => division.teams.length > 0)
    },
    breakpoint () {
      return this.$vuetify.breakpoint
    },
    fontSize () {
      return this.$vuetify.breakpoint.xs ? 'font-size: small;' : this.$vuetify.breakpoint.sm ? 'font-size: medium;' : ''
    },
    dateDisplay () {
      return this.$options.filters.readableDateRange(this.tournament.startDate, this.tournament.endDate)
    },
    complete () {
      return this.tournament.statusId === StatusEnum.COMPLETE
    },
    upcoming () {
      return this.tournament.dateStatus === StatusEnum.UPCOMING
    },
    current () {
      return this.tournament.dateStatus === StatusEnum.INPROCESS
    },
    hasLocation () {
      return this.tournament.locations
    },
    pos () {
      return this.tournament.id === 145 ? 'left center' : 'center center'
    },
    noTitle () {
      return this.tournament.props.includes('noTitle')
    },
    bookmarked () {
      return this.user && this.user.bookmarks && this.user.bookmarks.find(f => f.id === this.tournament.id && f.type === 'tournament')
    },
    qTab () {
      const same = this.tournament.id === +this.$route.params.tournamentId
      return same ? this.$route.query.tab : null
    },
    showBrochure () {
      return this.tournament && this.tournament.props && this.tournament.props.includes('permenant-brochure')
    },
    active () {
      return this.tournament.publicRounds.length > 0 && !this.tournament.props.includes('show-info')
    }
  },
  methods: {
    bookMe () {
      if (!this.user) {
        this.$emit('blocked', 'You must be logged in to favorite a tournament')
        return
      }
      this.booking = true
      this.$VBL.user.toggleBookmark('tournament', this.tournament.id)
        .catch(e => console.log(e.response))
        .finally(() => { this.booking = false })
    },
    register (dto) {
      if (!this.tournament.bidProgram || !this.tournament.bidProgram.required) {
        this.registration.setDivision(dto.division)
        this.registration.window = dto.window
      }
      this.activeTab = 'register'
    },
    onRegistered (dto) {
      this.setTourney(dto)
      this.activeTab = 'information'
    },
    onAddToCart () {
      var copy = this.registration.isClubVClub ? { extraFields: this.registration.extraFields } : null
      this.registration = this.tournament.newRegistration(copy)
    },
    onPublish () {
      this.publishing = true
      this.$VBL.tournament.publish(this.tournament.id, !this.tournament.isPublic)
        .then((response) => {
          this.tournament.isPublic = response.data
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          this.publishing = false
        })
    },
    saveState (tab) {
      if (this.tournament.id !== +this.$route.params.tournamentId) return
      const query = {}
      Object.assign(query, this.$route.query)
      if (tab !== (query.tab)) {
        query.tab = tab

        this.$router.push({
          query
        })
      }
    },
    gotoSubdomain () {
      const u = this.tournament.organization.username
      const w = window.location
      if (u.includes('1440')) {
        window.location.href = `${w.protocol}//play.p1440.org/tournaments/current`
        return
      }
      const h = w.host.includes('p1440') ? 'volleyballlife.com' : w.host
      window.location.href = `${w.protocol}//${u}.${h}/tournaments/current`
    },
    gotoEvents () {
      if (this.theme.username === this.tournament.organization.username) {
        this.$router.push('/tournaments/current')
      } else {
        this.gotoSubdomain()
      }
    }
  },
  filters: {
    formatDate (date) {
      return moment(date).format('dddd, MMMM Do YYYY')
    }
  },
  components: {
    DivisionList,
    'tournament-registration': RegistrationUI,
    ResultsDisplay,
    Leaderboard,
    TeamSummary,
    ClubTeamSummary,
    SessionCalendar
  },
  watch: {
    activeTab: function (newTab, oldTab) {
      if (oldTab) {
        this.$ga.page(`${this.$route.name}#${newTab}`)
        // this.$vuetify.goTo(this.$refs.tabs, { offset: -50 })
      }
      this.$nextTick(() => {
        this.saveState(newTab)
      })
    },
    qTab: function (v) {
      if (v && this.activeTab !== v) this.activeTab = v
    }
  },
  created () {
    this.registration = this.tournament.newRegistration()
    if (this.complete) {
      const tab = this.tournament.props.includes('teamSum') ? 'teams' : 'results'
      this.activeTab = tab
    } else if (this.tab || this.qTab) {
      this.activeTab = this.qTab || this.tab
    }
  }
}
</script>

<style scoped>
.xs {
  font-size: small
}
.br-none {
  border-radius: 0;
}
>>> .v-tabs-bar {
  border-radius: 0 !important
}
.brochure {
  line-height: 1.5 !important;
}
</style>
