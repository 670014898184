<template>
  <v-container fluid class="px-0">
    <v-row class="text-center"   v-if="!division.complete">
      <v-col cols="12">
        <h3>Awaiting results</h3>
      </v-col>
    </v-row>
    <template v-else>
      <v-btn color="success" @click.stop="log" v-if="false">text</v-btn>
      <v-row dense v-for="(finish, i) in sortedPlaces" :key="i">
        <v-col>
          <place-card :finish="finish" :division="division"></place-card>
        </v-col>
      </v-row>
    </template>
  </v-container>
</template>

<script>
import PlaceCard from './PlaceCardNew.vue'
import { mapGetters } from 'vuex'
import { firstBy } from 'thenby'

export default {
  props: ['division', 'places'],
  computed: {
    ...mapGetters(['tournament']),
    brackets () {
      return this.division.brackets
    },
    sortedPlaces () {
      var x = this.places
      return x.sort(firstBy('place'))
    },
    aa () {
      return this.division.resultsGrouped
    }
  },
  methods: {
    log () {
      console.log(this.finishes)
    }
  },
  components: {
    PlaceCard
  }
}
</script>

<style>

</style>
