<template>
  <router-link
    v-if="!emitter"
    class="link color3--text"
    :to="{name: 'division-team', params: {tournamentId: tournamentId, teamId: team.id}}"
  >{{team.name}}</router-link>
  <span
    v-else
    class="link color3--text"
    @click.stop="$emit('link-click')"
  >{{team.name}}</span>
</template>

<script>
export default {
  props: ['team', 'emitter', 'tournamentId'],
  data () {
    return {
    }
  },
  computed: {
  },
  components: {
  }
}
</script>

<style scoped>
.link {
  text-decoration: underline;
  font-weight: 700;
  cursor: pointer;
}
</style>
