import * as types from './BracketTypes'
import Finish from './Finish'
import sum from 'lodash.sum'

export default class FinishValidator {
  finishes = []
  nonFinishes = []

  constructor (division) {
    this.division = division
    this.resultsOG = JSON.stringify(division.resultsDto)
    this.update()
  }

  _addFinishes () {
    let i = 0
    while (sum(this.finishes.map((d) => d.maxTeams)) < this.teamsInBracket) {
      const f = types[this.type].finishes[i++]
      this.finishes.push(new Finish(f.value, f.roundOf, f.maxTeams))
    }
    if (this.addNextFinish) {
      const f = types[this.type].finishes[i]
      this.finishes.push(new Finish(f.value, f.roundOf, f.maxTeams))
    }
  }

  _addTeams () {
    this.teams.forEach((team) => {
      if (team.finish === 999999999) team.finish = null

      const finish = this.finishes.find((f) => {
        return f.value === +team.finish
      })

      finish ? finish.teams.push(team) : this._handleNonFinish(team)
    })
  }

  update () {
    this.finishes = []
    this.nonFinishes = []
    this.nonFinishes.push(new Finish())
    this.nonFinishes[0].validOnlyWhenEmpty = this.division.sanctioningBodyId && this.division.sanctioningBodyId.startsWith('AVP')
    this._addFinishes()
    this._addTeams()
  }

  _handleNonFinish (team) {
    let finish = this.nonFinishes.find((f) => {
      return f.value === (team.finish || 9999999)
    })
    if (!finish) {
      finish = new Finish(team.finish)
      const p = this.nonFinishes.pop()
      this.nonFinishes.push(finish, p)
    }
    finish.teams.push(team)
  }

  reset () {
    const og = JSON.parse(this.resultsOG)
    og.forEach(team => {
      if (team.finish === 999999999) team.finish = null
    })
    this.division.update({ teams: og })
  }

  // GETTERS
  get teams () {
    return this.division.teams.filter(f => !f.isDeleted)
  }

  get type () {
    return this.division.playoffFormat
  }

  get valid () {
    return !this.finishes.find((f) => !f.valid) && this.nonFinishes.valid
  }

  get teamsInBracket () {
    // return this.division.poolPlay ? this.division.playoffTeams : this.division.teams.length
    return this.division.teamCount
  }

  get addNextFinish () {
    return false // this.division.consolationStyle.toLowerCase().startsWith('next') || this.division.isAVP
  }

  get dirty () {
    return this.resultsOG !== JSON.stringify(this.division.resultsDto)
  }
}
