<template>
  <v-container class="px-0" v-if="!treeView" fluid>
    <template v-if="active">
      <division-list-row-active
        v-for="(division, i) in divisions"
        :key="division.id"
        :division="division"
        :last="(i + 1) === divisions.length"
      ></division-list-row-active>
    </template>
    <template v-else>
      <division-list-row
        v-for="(division, i) in divisions"
        :key="division.id"
        :division="division"
        :last="(i + 1) === divisions.length"
        @register-click="registerClick"
      ></division-list-row>
    </template>
  </v-container>
  <v-container fluid v-else>
    <v-expansion-panels v-model="tree.open" multiple v-if="tree">
      <v-expansion-panel
        v-for="branch in tree.items"
        :key="branch.name"
        class="grey lighten-4"
      >
      <v-expansion-panel-header class="px-2 title">
        {{branch.name}}
        <template v-slot:actions>
          <v-icon color="color3">
            fas fa-caret-down
          </v-icon>
        </template>

      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-expansion-panels v-model="branch.open" multiple>
          <v-expansion-panel
            v-for="limb in branch.children"
            :key="limb.name"
          >
          <v-expansion-panel-header class="px-2 title">
            {{limb.name}}
            <template v-slot:actions>
              <v-icon color="color3">
                fas fa-caret-down
              </v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-expansion-panels>
              <v-expansion-panel
                v-for="leaf in limb.children"
                :key="leaf.name"
                class="grey lighten-4"
              >
              <v-expansion-panel-header>
                {{leaf.name}}
                <template v-slot:actions>
                  <v-icon color="color3">
                    fas fa-caret-down
                  </v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <template v-if="active">
                  <division-list-row-active
                    v-for="(division, i) in leaf.divisions"
                    :key="division.id"
                    :division="division"
                    :last="(i + 1) === leaf.divisions.length"
                  ></division-list-row-active>
                </template>
                <template v-else>
                  <division-list-row
                    v-for="(division, i) in leaf.divisions"
                    :key="division.id"
                    :division="division"
                    :last="(i + 1) === leaf.divisions.length"
                    @register-click="registerClick"
                    listClass="grey lighten-4"
                  ></division-list-row>
                </template>
              </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import DivisionListRow from './DivisionListRow'
import DivisionListRowActive from './DivisionListRowActive'
import uniq from 'lodash.uniq'

export default {
  props: ['divisions', 'mode'],
  data () {
    return {
      tree: null,
      branches: []
    }
  },
  computed: {
    ...mapGetters(['admin', 'publicView', 'tournament']),
    active () {
      return this.tournament.publicRounds.length > 0 && this.tournament.isCompetitive
    },
    _mode () {
      return this.mode
        ? this.mode
        : this.$vuetify.breakpoint.xs
          ? 'list'
          : 'table'
    },
    divisionHeaders () {
      return [
        { text: 'Type', value: 'ageType.name', align: 'left' },
        { text: 'Gender', value: 'gender.name', align: 'left' },
        { text: 'Division', value: 'division.name', align: 'left' },
        { text: 'Entry Fee', value: 'currentEntryFee.amount', align: 'left' }
      ]
    },
    treeView () {
      return this.tournament.props.includes('tree')
    },
    tree2 () {
      const ages = uniq(this.divisions.map(m => m.ageType.name)).sort()
      const genders = uniq(this.divisions.map(m => m.gender.name)).sort()
      const types = uniq(this.divisions.map(m => m.numOfPlayers)).sort()
      const tree = []

      ages.forEach(a => {
        genders.forEach(g => {
          types.forEach(t => {
            const d = this.divisions.filter(f => f.ageType.name === a && f.gender.name === g && f.numOfPlayers === t)
            if (d.length) {
              let branch = tree.find(f => f.name === a)
              if (!branch) {
                branch = { name: a, children: [] }
                tree.push(branch)
              }
              let limb = branch.children.find(f => f.name === g)
              if (!limb) {
                limb = { name: g, children: [] }
                branch.children.push(limb)
              }
              let leaf = limb.children.find(f => f.name === `${t}'s`)
              if (!leaf) {
                leaf = { name: `${t}'s`, divisions: d }
                limb.children.push(leaf)
              }
            }
          })
        })
      })

      return tree
    }
  },
  methods: {
    registerClick (dto) {
      this.$emit('register-click', dto)
    },
    createTree () {
      const ages = uniq(this.divisions.map(m => m.ageType.name)).sort()
      const genders = uniq(this.divisions.map(m => m.gender.name)).sort()
      const types = uniq(this.divisions.map(m => m.numOfPlayers)).sort()
      const tree = { open: [], items: [] }

      ages.forEach((a, ai) => {
        genders.forEach((g, gi) => {
          types.forEach((t, ti) => {
            const d = this.divisions.filter(f => f.ageType.name === a && f.gender.name === g && f.numOfPlayers === t)
            if (d.length) {
              tree.open.push(ai)
              let branch = tree.items.find(f => f.name === a)
              if (!branch) {
                branch = { name: a, children: [], open: [] }
                tree.items.push(branch)
              }
              let limb = branch.children.find(f => f.name === g)
              if (!limb) {
                limb = { name: g, children: [] }
                branch.children.push(limb)
              }
              let leaf = limb.children.find(f => f.name === `${t}'s`)
              if (!leaf) {
                leaf = { name: `${t}'s`, divisions: d }
                limb.children.push(leaf)
              }
            }
          })
        })
      })
      tree.open = tree.items.map((m, i) => i)
      tree.items.forEach(f => {
        f.open = f.children.map((m, i) => i)
      })

      this.tree = tree
    }
  },
  components: {
    DivisionListRow,
    DivisionListRowActive
  },
  mounted () {
    this.createTree()
  }
}
</script>

<style scoped>
span {
  font-weight: 600;
  font-size: small;
}
>>> .v-expansion-panel-content__wrap {
  padding: 9px !important;
}
</style>
